/* Stripe related configuration.

NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Sharetribe Console.
*/

export const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
export const dayCountAvailableForBooking = 90;

/**
 * Default merchant category code (MCC)
 * MCCs are used to classify businesses by the type of goods or services they provide.
 *
 * In this template, we use code 5734 Computer Software Stores as a default for all the connected accounts.
 *
 * See the whole list of MCC codes from https://stripe.com/docs/connect/setting-mcc#list
 */
export const defaultMCC = '5734';

/*
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global

You can find the bank account formats from https://stripe.com/docs/connect/payouts-bank-accounts
*/

export const supportedCountries = [
  {
    //Afganistán
    code: 'Afganistan',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Albania
    code: 'Albania',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Algeria
    code: 'Algeria',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Samoa Americana
    code: 'SamoaAmericana',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Andorra
    code: 'Andorra',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Angola
    code: 'Angola',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Anguilla
    code: 'Anguilla',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Antártida
    code: 'Antartida',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Antigua y Barbuda
    code: 'AntiguayBarbuda',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Argentina
    code: 'Argentina',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Aruba
    code: 'Aruba',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Australia
    code: 'Australia',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    // Austria
    code: 'Austria',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Azerbaiyán
    code: 'Azerbaiyan',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bahamas
    code: 'Bahamas',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bahrein
    code: 'Bahrein',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bangladesh
    code: 'Bangladesh',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Barbados
    code: 'Barbados',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bielorrusia
    code: 'Bielorrusia',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    // Belgium
    code: 'Belgium',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Bélgica
    code: 'Belgica',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Belice
    code: 'Belice',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Benín
    code: 'Benin',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bermuda
    code: 'Bermuda',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bután
    code: 'Butan',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bolivia
    code: 'Bolivia',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bosnia-Herzegovina
    code: 'BosniaHerzegovina',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Botswana
    code: 'Botswana',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Brasil
    code: 'Brasil',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Brunei
    code: 'Brunei',
    currency: 'AUD',
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    //Bulgaria
    code: 'Bulgaria',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Burkina Faso
    code: 'BurkinaFaso',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Burundi
    code: 'Burundi',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Cambodia
    code: 'Cambodia',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Camerún
    code: 'Camerun',
    currency: 'BGN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Canadá
    code: 'Canada',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Cabo Verde
    code: 'CaboVerde',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Islas Caimán
    code: 'IslasCaiman',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // República Centroafricana
    code: 'RepublicaCentroafricana',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Chad
    code: 'Chad',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Chile
    code: 'Chile',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // China
    code: 'China',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Isla de Navidad
    code: 'IsladeNavidad',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  {
    // Islas Cocos
    code: 'IslasCocos',
    currency: 'CAD',
    accountConfig: {
      transitNumber: true,
      institutionNumber: true,
      accountNumber: true,
    },
  },
  //colombia
  {
    code: 'Colombia',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Comores
  {
    code: 'Comores',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //República del Congo
  {
    code: 'RepublicadelCongo',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //República Democrática del Congo
  {
    code: 'RepublicaDemocraticadelCongo',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Islas Cook
  {
    code: 'IslasCook',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Costa Rica
  {
    code: 'CostaRica',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Costa de Marfíl
  {
    code: 'CostadeMarfil',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Croacia
  {
    code: 'Croacia',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  //Cuba
  {
    code: 'Cuba',
    currency: 'COP',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Chipre
    code: 'Chipre',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //	"República Checa
    code: 'RepublicaCheca',
    currency: 'CZK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Dinamarca
    code: 'Dinamarca',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Alemania
    code: 'Alemania',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Djibouti
    code: 'Djibouti',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Dominica
    code: 'Dominica',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // República Dominicana
    code: 'RepublicaDominicana',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Ecuador
    code: 'Ecuador',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Estonia
    code: 'Estonia',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Egipto
    code: 'Egipto',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // El Salvador
    code: 'ElSalvador',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guinea Ecuatorial
    code: 'GuineaEcuatorial',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Eritrea
    code: 'Eritrea',
    currency: 'DKK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Etiopía
    code: 'Etiopia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Islas Malvinas
    code: 'IslasMalvinas',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Islas Feroe
    code: 'IslasFeroe',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Fiji
    code: 'Fiji',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guyana Francesa
    code: 'GuyanaFrancesa',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Polinesia Francesa
    code: 'PolinesiaFrancesa',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tierras Australes y Antárticas Francesas
    code: 'TierrasAustralesyAntarticasFrancesas',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Gabón
    code: 'Gabon',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Gambia
    code: 'Gambia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Georgia
    code: 'Georgia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Ghana
    code: 'Ghana',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Gibraltar
    code: 'Gibraltar',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Greece
    code: 'Grecia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Groenlandia
    code: 'Groenlandia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Granada
    code: 'Granada',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guadalupe
    code: 'Guadalupe',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guam
    code: 'Guam',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guatemala
    code: 'Guatemala',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guinea
    code: 'Guinea',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guinea-Bissau
    code: 'GuineaBissau',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Guyana
    code: 'Guyana',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Haití
    code: 'Haiti',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Vaticano
    code: 'Vaticano',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Honduras
    code: 'Honduras',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Hong Kong
    code: 'HongKong',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Hungría
    code: 'Hungria',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Islandia
    code: 'Islandia',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // India
    code: 'India',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Indonesia
    code: 'Indonesia',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Irán
    code: 'Iran',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Iraq
    code: 'Iraq',
    currency: 'HKD',
    accountConfig: {
      clearingCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Ireland
    code: 'Irlanda',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Israel
    code: 'Israel',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Italy
    code: 'Italia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Jamaica
    code: 'Jamaica',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Japan
    code: 'Japon',
    currency: 'JPY',
    accountConfig: {
      bankName: true,
      branchName: true,
      bankCode: true,
      branchCode: true,
      accountNumber: true,
      accountOwnerName: true,
    },
  },
  {
    // Jordania
    code: 'Jordania',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Kazajstán
    code: 'Kazajstan',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Kenia
    code: 'Kenia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Kiribati
    code: 'Kiribati',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Corea del Norte
    code: 'CoreadelNorte',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Corea del Sur
    code: 'CoreadelSur',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Kuwait
    code: 'Kuwait',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Laos
    code: 'Laos',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Latvia
    code: 'Letonia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Líbano
    code: 'Libano',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Lesotho
    code: 'Lesotho',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Liberia
    code: 'Liberia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Libia
    code: 'Libia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Liechtenstein
    code: 'Liechtenstein',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Lithuania
    code: 'Lituania',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Luxemburgo
    code: 'Luxemburgo',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Macao
    code: 'Macao',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Macedonia
    code: 'Macedonia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Madagascar
    code: 'Madagascar',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Malawi
    code: 'Malawi',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Malasia
    code: 'Malasia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Maldivas
    code: 'Maldivas',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mali
    code: 'Mali',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Malta
    code: 'Malta',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Islas Marshall
    code: 'IslasMarshall',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Martinica
    code: 'Martinica',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mauritania
    code: 'Mauritania',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mauritius
    code: 'Mauritius',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mayotte
    code: 'Mayotte',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Mexico
    code: 'MX',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Estados Federados de Micronesia
    code: 'EstadosFederadosdeMicronesia',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Moldavia
    code: 'Moldavia',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Mónaco
    code: 'Monaco',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Mongolia
    code: 'Mongolia',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Montserrat
    code: 'Montserrat',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Marruecos
    code: 'Marruecos',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Mozambique
    code: 'Mozambique',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Myanmar
    code: 'Myanmar',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Namibia
    code: 'Namibia',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Nauru
    code: 'Nauru',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Nepal
    code: 'Nepal',
    currency: 'MXN',
    accountConfig: {
      clabe: true,
    },
  },
  {
    // Netherlands
    code: 'Holanda',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Antillas Holandesas
    code: 'AntillasHolandesas',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Nueva Caledonia
    code: 'NuevaCaledonia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // New Zealand
    code: 'NuevaZelanda',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Nicaragua
    code: 'Nicaragua',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Niger
    code: 'Niger',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Nigeria
    code: 'Nigeria',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Niue
    code: 'Niue',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Islas Norfolk
    code: 'IslasNorfolk',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Islas Marianas del Norte
    code: 'IslasMarianasdelNorte',
    currency: 'NZD',
    accountConfig: {
      accountNumber: true,
    },
  },
  {
    // Norway
    code: 'Noruega',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Omán
    code: 'Oman',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Pakistán
    code: 'Pakistan',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Palau
    code: 'Palau',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Palestina
    code: 'Palestina',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Panamá
    code: 'Panama',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Papua Nueva Guinea
    code: 'PapuaNuevaGuinea',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Paraguay
    code: 'Paraguay',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Perú
    code: 'Peru',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Filipinas
    code: 'Filipinas',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Pitcairn
    code: 'Pitcairn',
    currency: 'NOK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Poland
    code: 'Polonia',
    currency: 'PLN',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Portugal
    code: 'Portugal',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Puerto Rico
    code: 'PuertoRico',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Qatar
    code: 'Qatar',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Reunión
    code: 'Reunion',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Romania
    code: 'Rumania',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Rusia
    code: 'Rusia',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Ruanda
    code: 'Ruanda',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Santa Helena
    code: 'SantaHelena',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    //San Kitts y Nevis
    code: 'SanKittsyNevis',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Santa Lucía
    code: 'SantaLucia',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // San Vicente y Granadinas
    code: 'SanVicenteyGranadinas',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Samoa
    code: 'Samoa',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // San Marino
    code: 'SanMarino',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Santo Tomé y Príncipe
    code: 'SantoTomeyPrincipe',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Arabia Saudita
    code: 'ArabiaSaudita',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Senegal
    code: 'Senegal',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Serbia and Montenegro
    code: 'SerbiaandMontenegro',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Seychelles
    code: 'Seychelles',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sierra Leona
    code: 'SierraLeona',
    currency: 'RON',
    accountConfig: {
      iban: true,
    },
  },

  {
    // Singapore
    code: 'Singapur',
    currency: 'SGD',
    accountConfig: {
      bankCode: true,
      branchCode: true,
      accountNumber: true,
    },
  },
  {
    // Slovakia
    code: 'Eslovaquia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Slovenia
    code: 'Eslovenia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Islas Salomón
    code: 'IslasSalomon',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Somalia
    code: 'Somalia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sudáfrica
    code: 'Sudafrica',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Spain
    code: 'Espana',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sri Lanka
    code: 'SriLanka',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sudán
    code: 'Sudan',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    //Surinam
    code: 'Surinam',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Swazilandia
    code: 'Swazilandia',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Suecia
    code: 'Suecia',
    currency: 'SEK',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Suiza
    code: 'Suiza',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Siria
    code: 'Siria',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Taiwán
    code: 'Taiwan',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tadjikistan
    code: 'Tadjikistan',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tanzania
    code: 'Tanzania',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tailandia
    code: 'Tailandia',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Timor Oriental
    code: 'TimorOriental',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Togo
    code: 'Togo',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tokelau
    code: 'Tokelau',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tonga
    code: 'Tonga',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Trinidad y Tobago
    code: 'TrinidadyTobago',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Túnez
    code: 'Tunez',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Turquía
    code: 'Turquia',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Turkmenistan
    code: 'Turkmenistan',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Islas Turcas y Caicos
    code: 'IslasTurcasyCaicos',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Tuvalu
    code: 'Tuvalu',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Uganda
    code: 'Uganda',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Ucrania
    code: 'Ucrania',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // Emiratos Árabes Unidos
    code: 'EmiratosArabesUnidos',
    currency: 'CHF',
    accountConfig: {
      iban: true,
    },
  },
  {
    // United Kingdom
    code: 'ReinoUnido',
    currency: 'GBP',
    accountConfig: {
      sortCode: true,
      accountNumber: true,
    },
  },
  {
    // United States
    code: 'EstadosUnidos',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Uruguay
    code: 'Uruguay',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Uzbekistán
    code: 'Uzbekistan',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Vanuatu
    code: 'Vanuatu',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Venezuela
    code: 'Venezuela',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Vietnam
    code: 'Vietnam',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Islas Vírgenes Británicas
    code: 'IslasVirgenesBritanicas',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Islas Vírgenes Americanas
    code: 'IslasVirgenesAmericanas',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Wallis y Futuna
    code: 'WallisyFutuna',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    //Sáhara Occidental
    code: 'SaharaOccidental',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Yemen
    code: 'Yemen',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Zambia
    code: 'Zambia',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Zimbabwe
    code: 'Zimbabwe',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Finlandia
    code: 'Finlandia',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
  {
    // Francia
    code: 'Francia',
    currency: 'USD',
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
];
