import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ACCOUNT_VALIDATEEXP_PAGES } from '../../routing/routeConfiguration';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, user } = props;
  const classes = classNames(rootClassName || css.root, className);

  const validation =
    user &&
    user?.attributes?.profile?.publicData['approve-experience'] &&
    user?.attributes?.profile?.publicData['approve-for-services'];
  const isClient = user && user?.attributes?.profile?.publicData.typeuser === 'cliente';

  let tabs = [
    {
      /*text: <FormattedMessage id="UserNav.profileSettings" />,*/
      text: <FormattedMessage id="UserNav.profile" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
  ];

  if (!isClient) {
    tabs.push({
      text: <FormattedMessage id="UserNav.validateExperience" />,
      selected: ACCOUNT_VALIDATEEXP_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ValidateExperiencePage',
      },
    });
  }

  tabs.push({
    text: <FormattedMessage id="UserNav.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  });

  if (validation) {
    tabs.push({
      text: <FormattedMessage id="UserNav.services" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    });
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
