import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AvatarSmall, NamedLink, ResponsiveImage } from '../../components';
import icTrophy from '../../assets/Trophy.svg';
import icStar from '../../assets/ic_star.svg';
import location from '../../../src/assets/Location.svg';
import video from '../../../src/assets/Video.svg';
import arrow from '../../../src/assets/arrow.svg';
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    reviews,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = currentListing.attributes.publicData.author;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  let nivelCoach = currentListing.attributes.publicData.nivelCoach;
  nivelCoach = nivelCoach?.replace(/-/g, ' ');
  const firstLetter = nivelCoach?.charAt(0);
  const remainingLetters = nivelCoach?.substring(1);
  const firstLetterCap = firstLetter?.toUpperCase();
  nivelCoach = firstLetterCap + remainingLetters;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.card}>
        <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
          <AvatarSmall user={author} className={css.providerAvatar} />
        </div>

        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            width: '100%',
            marginRight: '32px',
          }}
        >
          <div className={css.authorName}>{authorName}</div>
          
          <div style={{ display: 'inline-flex', flexDirection: 'row', marginTop: '8px' }}>
            <img src={icTrophy} />
            <div className={css.typeCoach}>{nivelCoach}</div>
            {/*
            <div className={css.reviews}>
              <img src={icStar} />1 opiniones
            </div>*/}
          </div>
          <div className={css.serviceDescription}>{currentListing.attributes.description}</div>
          <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
            <div className={css.consulta}>Consulta:</div>
            <div style={{ display: 'inline-flex', flexDirection: 'row', marginTop: '8px' }}>
              {currentListing.attributes.publicData.visitaPresencial && (
                <div
                  className={css.typeAppointment}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                >
                  <img src={location} style={{ marginRight: '4px' }} />
                  Presencial
                </div>
              )}

              {currentListing.attributes.publicData.visitaEnLinea && (
                <div className={css.typeAppointment} style={{ marginLeft: '16px' }}>
                  <img src={video} style={{ marginRight: '4px' }} />
                  Remoto
                </div>
              )}
            </div>
          </div>
          {currentListing.attributes.publicData.visitaPresencial && 'Visita presencial' && (
            <>
              <hr style={{ border: '1px solid #C6C7C8', width: '100%', marginBottom: '8px' }} />
              <div className={css.typeAppointment}>
                {currentListing.attributes.publicData.location.address}
              </div>
            </>
          )}
        </div>

        <div style={{ width: '5%', height: '100%', alignSelf: 'center' }}>
          <img src={arrow} />
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
